import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
} from "reactstrap";
import logo from "../../Assets/images/ss.png";
import "./header.scss";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [isSolutionOpen, setisSolutionOpen] = useState(false);
  const [isProductOpen, setisProductOpen] = useState(false);
  const [isServiceOpen, setisServiceOpen] = useState(false);
  const [isSUpportOpen, setisSUpportOpen] = useState(false);
  const [isWhySchoolSmartOpen, setisWhySchoolSmartOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  return (
    <div className="headerSection">
      {/* <div className="container-fluid"> */}

      <Navbar color="" light expand="md">
        <div className="nav-top">
          <Nav className="" navbar>
            <NavItem className="mt-2">
              <NavLink href="">Portal</NavLink>
            </NavItem>
            <NavItem className="mt-2">
              <NavLink href="/product">Support</NavLink>
            </NavItem>
            <NavbarText>
          <a href="contactUs"><button style={{textDecoration:"none"}} className="btn btn-primary contactBtn">Contact us</button></a>    
               
            </NavbarText>
          </Nav>
        </div>
        <NavbarBrand href="/" style={{ marginLeft: 10 }}>
          <img src={logo} alt="talent map" width="150" />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ms-auto" navbar>
            <UncontrolledDropdown
              inNavbar
              nav
              isOpen={isSolutionOpen}
              onMouseOver={() => setisSolutionOpen(true)}
              onMouseLeave={() => setisSolutionOpen(false)}
            >
              <DropdownToggle caret nav>
                Solutions
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  <Link style={{ textDecoration: "none" }} to="/oneBranchSchool">
                    {" "}
                    One Brand School
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link style={{ textDecoration: "none" }} to="/mbSchool">
                    {" "}
                    Multi-Branched Schools
                  </Link>
                </DropdownItem>
                {/* <DropdownItem divider /> */}
                <DropdownItem>
                  <Link style={{ textDecoration: "none" }} to="/gpSchool">
                    {" "}
                    Group of Schools
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link style={{ textDecoration: "none" }} to="/HybridSchool">
                    {" "}
                    Hybrid Schools
                  </Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem>
              <UncontrolledDropdown
                inNavbar
                nav
                isOpen={isProductOpen}
                onMouseOver={() => setisProductOpen(true)}
                onMouseLeave={() => setisProductOpen(false)}
              >
                <DropdownToggle caret nav>
                  Products
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    onClick={() =>
                      localStorage.setItem("product-name", "Student Smart")
                    }
                  >
                    <Link style={{ textDecoration: "none" }} to="/studentSmart">
                      {" "}
                      Student Smart
                    </Link>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      localStorage.setItem("product-name", "HR Smart")
                    }
                  >
                    <Link style={{ textDecoration: "none" }} to="/hrSmart">
                      HR Smart
                    </Link>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      localStorage.setItem("product-name", "Academic Smart")
                    }
                  >
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/academicSmart"
                    >
                      {" "}
                      Academic Smart
                    </Link>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      localStorage.setItem(
                        "product-name",
                        "Communication Smart"
                      )
                    }
                  >
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/communicationSmart"
                    >
                      Communication Smart
                    </Link>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      localStorage.setItem("product-name", "Pastoral Smart")
                    }
                  >
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/pastoralSmart"
                    >
                      Wellbeing Smart
                    </Link>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      localStorage.setItem("product-name", "Timetable Smart")
                    }
                  >
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/timetableSmart"
                    >
                      Timetable Smart
                    </Link>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      localStorage.setItem("product-name", "Library Smart")
                    }
                  >
                    <Link style={{ textDecoration: "none" }} to="/librarySmart">
                      Library Smart
                    </Link>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      localStorage.setItem(
                        "product-name",
                        "Administration Smart"
                      )
                    }
                  >
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/administrationSmart"
                    >
                      {" "}
                      Administration Smart
                    </Link>
                  </DropdownItem>

                  <DropdownItem
                    onClick={() =>
                      localStorage.setItem("product-name", "Reporting System")
                    }
                  >
                    <Link style={{ textDecoration: "none" }} to="/reportSmart">
                      Reporting System
                    </Link>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      localStorage.setItem("product-name", "Finance Smart")
                    }
                  >
                    <Link style={{ textDecoration: "none" }} to="/financeSmart">
                      Finance Smart
                    </Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>
            <NavItem>
              <UncontrolledDropdown
                inNavbar
                nav
                isOpen={isServiceOpen}
                onMouseOver={() => setisServiceOpen(true)}
                onMouseLeave={() => setisServiceOpen(false)}
              >
                <DropdownToggle caret nav>
                  Services
                </DropdownToggle>

                <DropdownMenu right>
                  <DropdownItem>
                    <Link style={{ textDecoration: "none" }} to="/consultancy">
                      {" "}
                      Consultancy
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link style={{ textDecoration: "none" }} to="/caseStudy">
                      {" "}
                      Case Study
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link style={{ textDecoration: "none" }} to="/installation">
                      Installation
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link style={{ textDecoration: "none" }} to="/migration">
                      Migration
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link style={{ textDecoration: "none" }} to="/integration">
                      Integration
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link style={{ textDecoration: "none" }} to="/training">
                      Training
                    </Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>
            <NavItem>
              <UncontrolledDropdown
                inNavbar
                nav
                isOpen={isSUpportOpen}
                onMouseOver={() => setisSUpportOpen(true)}
                onMouseLeave={() => setisSUpportOpen(false)}
              >
                <DropdownToggle caret nav>
                  Support & Resources
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <Link style={{ textDecoration: "none" }} to="/chatbot">
                      Chatbot
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link style={{ textDecoration: "none" }} to="/technicalSupport">
                      Technical Support Contact
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link style={{ textDecoration: "none" }} to="/helpGuide">
                      Help Guides
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link style={{ textDecoration: "none" }} to="/trainingWebinar">
                    Training Webinars
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link style={{ textDecoration: "none" }} to="/trainingVideos">
                    Training Videos
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link style={{ textDecoration: "none" }} to="/studentParent">
                    Students & Parents
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link style={{ textDecoration: "none" }} to="/affiliatesPartner">
                    Affiliate Partners
                    </Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>

            <NavItem>
              <UncontrolledDropdown
                inNavbar
                nav
                isOpen={isWhySchoolSmartOpen}
                onMouseOver={() => setisWhySchoolSmartOpen(true)}
                onMouseLeave={() => setisWhySchoolSmartOpen(false)}
              >
                <DropdownToggle caret nav>
                  Why SchoolSmart
                </DropdownToggle>
                <DropdownMenu right>
                  
                  <DropdownItem>
                    <Link style={{ textDecoration: "none" }} to="/aboutUs">
                      About Us
                    </Link>
                  </DropdownItem>
                  {/* <DropdownItem>
                    <Link style={{ textDecoration: "none" }} to="/accerditations">
                      Accerditations
                    </Link>
                  </DropdownItem> */}
                  <DropdownItem>
                    <Link style={{ textDecoration: "none" }} to="/careers">
                      Careers
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link style={{ textDecoration: "none" }} to="/contactUs">
                      Contact Us
                    </Link>
                  </DropdownItem>
                  {/* <DropdownItem>
                    <Link style={{ textDecoration: "none" }} to="/event">
                      Event
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link style={{ textDecoration: "none" }} to="/press">
                      Press
                    </Link>
                  </DropdownItem> */}
                  <DropdownItem>
                    <Link style={{ textDecoration: "none" }} to="/collaboration">
                      Collaboration
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link style={{ textDecoration: "none" }} to="/regProcess">
                      Registeration Process
                    </Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
      {/* </div> */}
    </div>
  );
}
export default Header;
