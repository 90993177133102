import React from "react";
import "./Products.scss";
import step1Image from "../../Assets/images/sf2.png";
import { Link } from "react-router-dom";
import ProductWithButton from "../../components/ProductSesction/ProductWithButton";
import timetableScreen from "../../Assets/screens/timetable-s.png";
import Feature from "../../components/Features/Feature";
import timetable from "../../Assets/headers/TIMETABLE.jpg";
const TimetableSmart = (props) => {
  return (
    <div>
      <div
        className="heroSection"
        style={{ backgroundImage: "url(" + timetable + ")" }}
      >
        <div
          style={{
            backgroundColor: "#000",
            display: "inline-block",
            width: "100%",
            opacity: "0.7",
          }}
        >
          <div className="container">
            <div className="row">
              <h1 className="text-center text-light head">
                THE TimetableSmart!
              </h1>
              <p className="text-center text-light pTag">
                A unanimous requirement for planning class timings in school.
              </p>
              <p className="hp text-light">
                <Link className="text-light" to="/">
                  Home
                </Link>{" "}
                |{" "}
                <Link className="text-light" to="/product">
                  Products
                </Link>{" "}
              </p>
            </div>
          </div>
        </div>
        <br />
        <div className="textSection">
          <div className="container">
            <br />
            <h1 className="color" >
              Be On Clock and Manage School Time Effectively
            </h1>
            <p className="mt-4 bottom-text">
              The system can be deployed to schedule a new class, cancel an
              existing class, and making other changes to a timetable. It is
              simple and saves time and energy.
            </p>
          </div>
        </div>
      </div>
      <br />
      <ProductWithButton
        backgroundColor={"#d8fce9"}
        src={timetableScreen}
        heading={"TIMETABLE "}
        smallHeading={
          "A fully automated web-based timetable management tool that saves time and effort, create an error-free timetable, update students and teachers by instant notification."
        }
        text={
          "The ideal tool for scheduling and organising your school’s timetables. You can easily create an unlimited number of timetables, each of which are customised to your school’s specifications and style. Then you can add staff meetings, year schedules and teacher availability, all whilst highlighting any potential clashes or other issues."
        }
        link1={"/"}
        link2={"/SchedulSingleDemo"}
        videoLink={"https://schoolsmart360.com/videos/timetable-smart.mp4"}
      />
      <br />
      <div className="container">
        <h2 className="text-center font-feature">Features overview</h2>

        <div className="row mt-5">
          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/add-cut-off-time.cdeea1a4.svg"
              // }
              icon={"icon-student-timetable-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"STUDENT TIMETABLE"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"Student timetable"} />
            </p>
          </div>
          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/staff%20timetable.8cd82779.svg"
              // }
              icon={"icon-staff-timetable-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"STAFF TIMETABLE"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"Staff timtable. "} />
            </p>
          </div>

          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/consolidated%20timetable.1d1308fb.svg"
              // }
              icon={"icon-consoildated-timetable-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"CONSOLIDATED TIMETABLE"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"All school timetable in one view."} />
            </p>
          </div>
          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/academic%20calendar.7609f24b.svg"
              // }
              icon={"icon-academic-calender-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"ACADEMIC CALENDER"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"Events show in timetable."} />
            </p>
          </div>

          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/apply-substitution.8ab192eb.svg"
              // }
              icon={"icon-automatic-subtiution-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"AUTOMATIC SUBSITUTION"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"Automatic subsitution of staffs."} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TimetableSmart;
