import React from "react";
import "./Products.scss";
import administration from "../../Assets/headers/ADMINISTRATION.jpg";
import { Link } from "react-router-dom";
import ProductWithButton from "../../components/ProductSesction/ProductWithButton";
import administrationScreen from "../../Assets/screens/administration-s.png";
import Feature from "../../components/Features/Feature";
const AdministrationSmart = (props) => {
  return (
    <div>
      <div
        className="heroSection"
        style={{ backgroundImage: "url(" + administration + ")" }}
      >
        <div
          style={{
            backgroundColor: "#000",
            display: "inline-block",
            width: "100%",
            opacity: "0.7",
          }}
        >
          <div className="container">
            <div className="row">
              <h1  className="text-center text-light head">
                A fully integrated online module forstreamlining your all
                administrative tasks…
              </h1>
              <p className="text-center text-light pTag">
                This simple module stores your school setup and customisation
                details, so you can use your own terminology for students,
                teachers, forms, houses and year groups.
              </p>
              <p className="hp text-light">
                <Link className="text-light" to="/">
                  Home
                </Link>{" "}
                |{" "}
                <Link className="text-light" to="/product">
                  Products
                </Link>{" "}
              </p>
            </div>
          </div>
        </div>
        <br />
        <div className="textSection">
          <div className="container">
            <br />
            <h1  className="color">Run Your School Smoothly</h1>
            <p className="mt-4 bottom-text">
              A secure, configurable and centralised data management system for
              your entire school community.
            </p>
          </div>
        </div>
      </div>
      <br />
      <ProductWithButton
        backgroundColor={"#f4cdff"}
        src={administrationScreen}
        heading={"ADMINISTRATION "}
        smallHeading={
          "A cloud-based solution for all your administrative requirements"
        }
        text={
          "At the core of a successfully run school is the administrative tasks to support, our suite of modules includes everything from school admissions, HR and census right through to data protection. Allowing your administration office to take complete control over your schools’ operations."
        }
        link1={"/"}
        link2={"/SchedulSingleDemo"}
        videoLink={"https://schoolsmart360.com/videos/administration-smart.mp4"}
      />
      <br />
      <div className="container">
        <h2 className="text-center font-feature">Features overview</h2>

        <div className="row mt-5">
          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/inventory.31f2eab8.svg"
              // }
              icon={"icon-inventory-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"INVENTORY"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"Handle all school inventory."} />
            </p>
          </div>
          <div className="col-md-2">
            <Feature
              // img={"http://schoolsmart.org.uk/static/media/campus.55e4b771.svg"}
              icon={"icon-campus-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"CAMPUS"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"All school campuses. "} />
            </p>
          </div>

          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/canteen.0a40e6b0.svg"
              // }
              icon={"icon-canteen-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"CANTEEN"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"School canteen stuffs."} />
            </p>
          </div>
          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/vehicle.1a7cbd73.svg"
              // }
              icon={"icon-transport-module-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
              
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"TRANSPORT MODULE"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"School transports and drives information."} />
            </p>
          </div>

          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/view%20items.3b5d4b70.svg"
              // }
              icon={"icon-procurement-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"PROCUREMENT"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"Manage procurement items. "} />
            </p>
          </div>
          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/campus%20security.2029cf0e.svg"
              // }
              icon={"icon-campus-security-icon  fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"CAMPUS SECURITY"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"Campus security with cctv cameras."} />
            </p>
          </div>
          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/campus%20maintenance.fde0af71.svg"
              // }
              icon={"icon-campus-maintinance-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"CAMPUS MAINTENANC"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"Managing campus cleaness ."} />
            </p>
          </div>
          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/floor%20plan%20icon.9356108c.svg"
              // }
              icon={"icon-floor-plan-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"FLOOR PLAN"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"Handle all floors issues."} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdministrationSmart;
