import React from "react";
import "./Products.scss";
import library from "../../Assets/headers/LIBRARY.jpg";
import { Link } from "react-router-dom";
import ProductWithButton from "../../components/ProductSesction/ProductWithButton";
import libraryScreen from "../../Assets/screens/library-s.png";
import Feature from "../../components/Features/Feature";
const LibrarySmart = (props) => {
  return (
    <div>
      <div
        className="heroSection"
        style={{ backgroundImage: "url(" + library + ")" }}
      >
        <div
          style={{
            backgroundColor: "#000",
            display: "inline-block",
            width: "100%",
            opacity: "0.7",
          }}
        >
          <div className="container">
            <div className="row">
              <h1  className="text-center text-light head">
                An online, fully integrated tool designed to manage all the
                functions of a library…
              </h1>
              <p className="text-center text-light pTag">
                Helps librarian to maintain the database of new books and the
                books that are borrowed by members along with their due dates.
              </p>
              <p className="hp text-light">
                <Link className="text-light" to="/">
                  Home
                </Link>{" "}
                |{" "}
                <Link className="text-light" to="/product">
                  Products
                </Link>{" "}
              </p>
            </div>
          </div>
        </div>
        <br />
        <div className="textSection">
          <div className="container">
            <br />
            <h1   className="color">
              Completely automates all your library's activities.
            </h1>
            <p className="mt-4 bottom-text" >
              Facilitate the administrators to keep an eye on all the library
              functions.
            </p>
          </div>
        </div>
      </div>
      <br />
      <ProductWithButton
        backgroundColor={"#e2f0d9"}
        src={libraryScreen}
        heading={"LIBRARY "}
        smallHeading={
          "Enables librarians and users to save time on daunting tasks and enhances efficiency"
        }
        text={
          "LibrarySmart offers a smart application for your school to systematically manage library operations, an opportunity to know how well-maintained the record of issued books and collection is. The librarian and the administration department can access various reports to implement new improvements."
        }
        link1={"/"}
        link2={"/SchedulSingleDemo"}
        videoLink={"https://schoolsmart360.com/videos/library-smart.mp4"}
      />
      <br />
      <div className="container">
        <h2 className="text-center font-feature">Features overview</h2>

        <div className="row mt-5">
          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/add%20book.9fb476cf.svg"
              // }
              icon={"icon-book-category-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"BOOKS CATEGORY"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"Multiple books categories in one system."} />
            </p>
          </div>
          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/view%20book.25aec178.svg"
              // }
              icon={"icon-book-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"BOOKS"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"All school books in one system."} />
            </p>
          </div>

          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/book%20details.471a3881.svg"
              // }
              icon={"icon-issue-book-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"ISSUE BOOKS"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"Records of issued books to student."} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LibrarySmart;
