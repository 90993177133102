import React, { useState } from 'react';
import "./richSolution.scss";
import SIDScreen from "../../Assets/screens/SID-screen-png.png";
import LMSScreen from "../../Assets/screens/LMS-screen.-png.png";
import AdmissionScreen from "../../Assets/screens/admission-screen-png.png";
import AcademicScreen from "../../Assets/screens/academic-screen-png.png";
import HRScreen from "../../Assets/screens/HR-screen-png.png";
import AdmissionLogo from "../../Assets/richFeatures/admission.png";
import AcademicLogo from "../../Assets/richFeatures/academic.png";
import HR from "../../Assets/richFeatures/hr.png";
import SID from "../../Assets/richFeatures/sid.png";
import LMS from "../../Assets/richFeatures/lms.png";
import { Link } from 'react-router-dom';

const RichSolution = () => {
  const [activeDiv, setactiveDiv] = useState(1);
  return <>
    <br />
    <div className="container mb-2 richSolutionSection">
      <p className="container mt-2 mb-5 featureText" >Feature rich, end-to-end solutions...</p>
      <div className="row mt-4 justify-content-center">
        <div className="col-md-2 text-center divHead col-2" onClick={() => setactiveDiv(1)}>
          <img src={SID} className="imgForDiv" />
          <br />
          <div className={activeDiv === 1 ? "activeDiv" : "nonActiveDiv"}>
            <label>SID</label>
          </div>
        </div>

        <div className="col-md-2 text-center divHead col-2" onClick={() => setactiveDiv(2)}>
          <img src={LMS} className="imgForDiv" />
          <br />
          <div className={activeDiv === 2 ? "activeDiv" : "nonActiveDiv"}>
            <label>LMS</label>
          </div>
        </div>
        <div className="col-md-2 text-center divHead col-2" onClick={() => setactiveDiv(3)}>
          <img src={AdmissionLogo} className="imgForDiv" />
          <br />
          <div className={activeDiv === 3 ? "activeDiv" : "nonActiveDiv"}>
            <label>ADMISSION</label>
          </div>
        </div>
        <div className="col-md-2 text-center divHead col-2" onClick={() => setactiveDiv(4)}>
          <img src={AcademicLogo} className="imgForDiv" />
          <br />
          <div className={activeDiv === 4 ? "activeDiv" : "nonActiveDiv"}>
            <label>ACADEMIC PLAN</label>
          </div>
        </div>
        <div className="col-md-2 text-center divHead col-2" onClick={() => setactiveDiv(5)}>
          <img src={HR} className="imgForDiv" />
          <br />
          <div className={activeDiv === 5 ? "activeDiv" : "nonActiveDiv"}>
            <label>HR</label>
          </div>
        </div>
      </div>

      {activeDiv === 1 ?
        <div className="row divContent">

          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <h4>SID</h4>

                <p className="fontTP">A secure, configurable, and centralized student
                information system with a suite of portals for
                parents, students, and staff, giving your school full
                control of all administrative, academic,
                admissions, and wellbeing information</p>

                <button className="btn1">Give us a call</button>
                <Link to="/SchedulSingleDemo">
                  <button className="btn2" onClick={() =>
                    localStorage.setItem("product-name", "SID")
                  } >Watch Demo</button>
                </Link>
              </div>
              <div className="col-md-6">
                <img className="img divContentImage" src={SIDScreen} alt="" />
              </div>
            </div>

          </div>
        </div>

        : activeDiv === 2 ?

          <div className="row divContent">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <h4>LMS</h4>

                  <p className="fontTP">A complete tool for teachers to provide standard
                  teaching materials, add all required information to
                  student and parent portal, easy to use, and fully
                  integrated with the management and staff</p>

                  <button className="btn1">Give us a call</button>
                  <Link to="/SchedulSingleDemo">
                    <button className="btn2" onClick={() =>
                      localStorage.setItem("product-name", "LMS")
                    } >Watch Demo</button>
                  </Link>
                </div>
                <div className="col-md-6">
                  <img className="img divContentImage" src={LMSScreen} alt="" />
                </div>
              </div>

            </div>
          </div>
          : activeDiv === 3 ?

            <div className="row divContent">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <h4>ADMISSION</h4>

                    <p className="fontTP">The paperless form sits within your school’s own
                    website so parents can access it quickly and
                    easily. From here, each parent can complete your
                    enquiry and registration forms online, as well as it
                    can be done in campus.</p>

                    <button className="btn1">Give us a call</button>
                    <Link to="/SchedulSingleDemo">
                      <button className="btn2" onClick={() =>
                        localStorage.setItem("product-name", "ADMISSION")
                      } >Watch Demo</button>
                    </Link>
                  </div>
                  <div className="col-md-6">
                    <img className="img divContentImage" src={AdmissionScreen} alt="" />
                  </div>
                </div>

              </div>
            </div>
            : activeDiv === 4 ?

              <div className="row divContent">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <h4>ACADEMIC PLAN</h4>

                      <p className="fontTP">This dashboard is designed to provide you to
                      setup your own school academic plan as per your
                      requirements. It a complete integrated and user
                      customizable suit required by most academic
                      heads</p>

                      <button className="btn1">Give us a call</button>
                      <Link to="/SchedulSingleDemo">
                        <button className="btn2" onClick={() =>
                          localStorage.setItem("product-name", "ACADEMIC PLAN")
                        } >Watch Demo</button>
                      </Link>
                    </div>
                    <div className="col-md-6">
                      <img className="img divContentImage" src={AcademicScreen} alt="" />
                    </div>
                  </div>

                </div>
              </div>
              : activeDiv === 5 ?

                <div className="row divContent">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <h4>HR</h4>

                        <p className="fontTP">It gives your school's HR faculty the opportunity to
                        focus on more important things than documenting
                        and logging structures – yet the chance to acquire
                        all-encompassing or independently based bits of
                        knowledge into everything staff-related.</p>

                        <button className="btn1">Give us a call</button>
                        <Link to="/SchedulSingleDemo">
                          <button className="btn2" onClick={() =>
                            localStorage.setItem("product-name", "HR")
                          } >Watch Demo</button>
                        </Link>

                      </div>
                      <div className="col-md-6">
                        <img className="img divContentImage" src={HRScreen} alt="" />
                      </div>
                    </div>

                  </div>
                </div>

                : null}

    </div>

  </>;
};
export default RichSolution;
