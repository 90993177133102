import Installations from "../../Assets/services/Installation.jpg";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import { Link } from "react-router-dom";
const Installation =()=>{
    return(
        <>
             <div
        className="heroSection"
        style={{ backgroundImage: "url(" + Installations + ")" }}
      >
        <div className="container">
          <div className="row">
            <h1 className="text-center text-light head">
            Installation…
            </h1>
            <p className="text-center text-light pTag">
            A whole new fully web-based system in just three steps away.
            </p>
          </div>
        </div>
        <br />
      </div>
      <div className="textSection">
        <div className="container">
          <br />
          <h1 className="color">
          Even if you are installing your first MIS system?
          </h1>
          <p className="mt-3 bottom-text">
          It’s easier than you might think. We’ve implemented SchoolSmart in many 
schools and the average installation takes us just six weeks from the 
moment you enable remote access.
          </p>
        </div>
      </div>
      <br></br>
      <ServiceSection
        s1={"Services"}
        s2={<Link to ="/migration" style={{textDecoration:'none',color:'black'}}>Migration</Link>}
        s3={"Services"}
        s4={<Link to ="/integration" style={{textDecoration:'none',color:'black'}}>Integration</Link>}
        s5={"Our Product"}
        s6={<Link to ="/product" style={{textDecoration:'none',color:'black'}}>Modules</Link>}
      />
        </>
    )
    }
    
    export default Installation;
    