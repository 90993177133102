import mbSchools from "../../Assets/solution/Multi-Branched-Schools.jpg";
import Question from "../../components/Questions/Question";
import RichSolution from "../../components/FeatureRichSolution/RichSolution";
const MBSchool = ()=>{

    return(
        <>
           <div
        className="heroSection"
        style={{ backgroundImage: "url(" + mbSchools + ")" }}
      >
        <div className="container">
          <div className="row">
            <h1 className="text-center text-light head">
            Fully Integrated Cloud-based MIS solution for Multi-Branched schools…
            </h1>
            <p className="text-center text-light pTag">
            A flexible, complete cloud-based school management system which supports multi-branched schools.
            </p>
          </div>
        </div>
        <br />
      </div>
      <div className="textSection">
        <div className="container">
          <br />
          <h1 className="color">
          Integrated software to grow with your school and enhance the experience for staff, students and parents
          </h1>
          <p className="mt-3 bottom-text">
          Many schools worldwide trust our system to support the future of education. We are one of the leading completely cloud-based information system providers.
          </p>
        </div>
      </div>
      <RichSolution />
      <br></br>
      <Question/>
        </>
    )
}

export default MBSchool