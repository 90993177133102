import React from "react";
import "./Feature.scss";

const Feature = (props) => {
  return (
    <div className="">
      <div className="container">
        <div className="row">
        <span style={{color:"#7fd0d5"}} className={props.icon } >
          <span className={props.inn1}></span>
          <span className={props.inn2}></span>
          <span className={props.inn3}></span>
          <span className={props.inn4}></span>
          <span className={props.inn5}></span>
          <span className={props.inn6}></span>
          <span className={props.inn7}></span>
          </span>
          <img src={props.img}  alt="" />
      <span ><h5> {props.heading}</h5>
          {props.text}</span>   
        </div>
      </div>
    </div>
  );
};
export default Feature;
