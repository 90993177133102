import React from "react";
import "./Products.scss";
import studentSmart from "../../Assets/headers/STUDENTSMART.jpg";
import { Link } from "react-router-dom";
import ProductWithButton from "../../components/ProductSesction/ProductWithButton";
import studentScreen from "../../Assets/screens/student-s.png";
import Feature from "../../components/Features/Feature";
const StudentSmart = (props) => {
  return (
    <div>
      <div
        className="heroSection"
        style={{ backgroundImage: "url(" + studentSmart + ")" }}
        //style={{ backgroundColor: "black" }}
      >
        <div
          style={{
            backgroundColor: "#000",
            display: "inline-block",
            width: "100%",
            opacity: "0.7",
          }}
        >
          <div className="container">
            <div className="row">
              <h1  className="text-center text-light head">
                Online or on-campus Student Registration and Admissions
                Program
              </h1>
              <p className="text-center text-light pTag">
                A streamlined and efficient way to manage your school's
                admissions process online
              </p>
              <p className="hp text-light">
                <Link className="text-light" to="/">
                  Home
                </Link>{" "}
                |{" "}
                <Link className="text-light" to="/product">
                  Products
                </Link>{" "}
              </p>
            </div>
          </div>
        </div>
        <br />
        <div className="textSection">
          <div className="container">
            <br />
            <h1 className="color">
              Take charge of your Admissions & Registrations
            </h1>
            <p className="mt-4 bottom-text">
              A more efficient admissions process for your school, reduce the
              time spent on administrative tasks, and fully automate your
              record-keeping.
            </p>
          </div>
        </div>
      </div>
      <br />
      <ProductWithButton
        backgroundColor={"#fff2cc"}
        src={studentScreen}
        heading={"STUDENT "}
        smallHeading={"An Efficient Paperless Admissions Process"}
        text={
          "The paperless form sits within your school’s own website so parents can access it quickly and easily. From here, each parent can complete your enquiry and registration forms online, as well as it can be done in campus."
        }
        link1={"/"}
        link2={"/SchedulSingleDemo"}
        videoLink={"https://schoolsmart360.com/videos/student-smart.mp4"}
      />
      <br />
      <div className="container">
        <h2 className="text-center font-feature">Features overview</h2>

        <div className="row mt-5">
          <div className="col-md-2">
            <Feature
           icon={"icon-student-admission-icon fa-6x"}
           inn1={"path1"}
           inn2={"path2"}
           inn3={"path3"}
           inn4={"path4"}
           inn5={"path5"}
           inn6={"path6"}
           inn7={"path7"}
           // icon={"fa fa-users fa-5x"}
              // img={
              //   "http://schoolsmart.org.uk/static/media/admission%20smart.5139e8f1.svg"
              // }
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"STUDENT ADMISSION"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature
                text={
                  "A complete admission process where you can attached all relevant documents with every students online"
                }
              />
            </p>
          </div>
          <div className="col-md-2">
            <Feature
            icon={"icon-entry-exams fa-6x"}
            inn1={"path1"}
            inn2={"path2"}
            inn3={"path3"}
            inn4={"path4"}
            inn5={"path5"}
            inn6={"path6"}
            inn7={"path7"}
            
              //  img={
              //   "http://schoolsmart.org.uk/static/media/entry%20exam.94289179.svg"
              // } 
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"ENTRY EXAMS"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature
                text={
                  "In admission process, entry exams is a key to take new induction, you can manage and maintain the full record of the process."
                }
              />
            </p>
          </div>

          <div className="col-md-2">
            <Feature
                icon={"icon-parents-registration-icon fa-6x"}
                inn1={"path1"}
                inn2={"path2"}
                inn3={"path3"}
                inn4={"path4"}
                inn5={"path5"}
                inn6={"path6"}
                inn7={"path7"}
          // img={
              //   "http://schoolsmart.org.uk/static/media/parent%20registration.6afd19eb.svg"
              // }
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"PARENT REGISTRATION"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature
                text={
                  "Create a family tree and register all siblings under one family tree for easy managing."
                }
              />
            </p>
          </div>
          <div className="col-md-2">

            <Feature
              icon={"icon-student-record-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}

            // img={
            //     "http://schoolsmart.org.uk/static/media/student%20record%20mangement.652d33d5.svg"
            //   }
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"STUDENT RECORDS"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature
                text={
                  "Create different reports of student admitted in your school."
                }
              />
            </p>
          </div>

          <div className="col-md-2">
            <Feature
            
            icon={"icon-online-registration-icon fa-6x"}
            inn1={"path1"}
            inn2={"path2"}
            inn3={"path3"}
            inn4={"path4"}
            inn5={"path5"}
            inn6={"path6"}
            inn7={"path7"}
              // img={
              //   "http://schoolsmart.org.uk/static/media/front%20desk.e1748bd5.svg"
              // }
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"ONLINE REGISTRATION FORM"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature
                text={
                  "Parents can enquire and register their kids online for admission. "
                }
              />
            </p>
          </div>
          <div className="col-md-2">
            <Feature
             icon={"icon-census-module-icon fa-6x"}
             inn1={"path1"}
             inn2={"path2"}
             inn3={"path3"}
             inn4={"path4"}
             inn5={"path5"}
             inn6={"path6"}
             inn7={"path7"}
          //  icon={"fa fa-chart-line fa-5x"}
              // img={
              //   "http://schoolsmart.org.uk/static/media/census%20module.89acf048.svg"
              // }
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"CENSUS MODULE"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature
                text={
                  "Full details on demographic of your school’s population can be found, using this tool also you can create various data reports."
                }
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StudentSmart;
