import Integrations from "../../Assets/services/Integration.jpg";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import { Link } from "react-router-dom";
const Integration =()=>{
    return(
        <>
           <div
        className="heroSection"
        style={{ backgroundImage: "url(" + Integrations + ")" }}
      >
        <div className="container">
          <div className="row">
            <h1 className="text-center text-light head">
            Connect with SchoolSmart
Integration…
            </h1>
            <p className="text-center text-light pTag">
            Builds on the power of your SchoolSmart modules by allowing integration with all 
sorts of third-party apps and systems.
            </p>
          </div>
        </div>
        <br />
      </div>
      <div className="textSection">
        <div className="container">
          <br />
          <h1 className="color">
          In-House Development…
          </h1>
          <p className="mt-3 bottom-text">
          Our team of in-house integrator who get in touch with our clients to guide 
about our APIs and how to create seamless integration. From curriculum 
management to facilities maintenance, school security to sports and social, 
the our team provides your school with the tools to integrate disparate 
systems and streamline processes.
          </p>
        </div>
      </div>
      <br></br>
      <ServiceSection
        s1={"Services"}
        s2={<Link to ="/installation" style={{textDecoration:'none',color:'black'}}>Installation</Link>}
        s3={"Services"}
        s4={<Link to ="/training" style={{textDecoration:'none',color:'black'}}>Training</Link>}
        s5={"Our Product"}
        s6={<Link to ="/product" style={{textDecoration:'none',color:'black'}}>Modules</Link>}
      />
        </>
    )
    }
    
    export default Integration;
    