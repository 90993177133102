import React from "react";
import "./Products.scss";
import step1Image from "../../Assets/images/sf2.png";
import { Link } from "react-router-dom";
import ProductWithButton from "../../components/ProductSesction/ProductWithButton";
import reportScreen from "../../Assets/screens/reporting-system.png";
import Feature from "../../components/Features/Feature";
import reportSmart from "../../Assets/headers/REPORTS.jpg";
const ReportSmart = (props) => {
  return (
    <div>
      <div
        className="heroSection"
        style={{ backgroundImage: "url(" + reportSmart + ")" }}
      >
        <div
          style={{
            backgroundColor: "#000",
            display: "inline-block",
            width: "100%",
            opacity: "0.7",
          }}
        >
          <div className="container">
            <div className="row">
              <h1  className="text-center text-light head">
                Measure & track your schools performance across a multitude of
                metrics
              </h1>
              <p className="text-center text-light pTag">
                Take data-backed decisions by analyzing real-time data &
                identifying potential issues in service delivery for your
                school.
              </p>
              <p className="hp text-light">
                <Link className="text-light" to="/">
                  Home
                </Link>{" "}
                |{" "}
                <Link className="text-light" to="/product">
                  Products
                </Link>{" "}
              </p>
            </div>
          </div>
        </div>
        <br />
        <div className="textSection">
          <div className="container">
            <br />
            <h1 className="color">Create Your Own Custom Reports</h1>
            <p className="mt-4 bottom-text">
              Supporting you with every one of your academic reporting
              requirements, our flexible School Reporting module is the perfect
              way to compile interim, end-of-term and annual reports which are
              tailored to your school’s individual needs and processes.
            </p>
          </div>
        </div>
      </div>
      <br />
      <ProductWithButton
        backgroundColor={"#fff2cc"}
        src={reportScreen}
        heading={"REPORT "}
        smallHeading={
          "It offers a wide-array of data reports across all its modules as intuitive visualizations."
        }
        text={
          "Multiple staff members can write reports for an individual student in the same subject, as it is a fully integrated and completely web-based tool. These reports can then be published in bulk to the relevant Student and Parent Portals."
        }
        link1={"/"}
        link2={"/SchedulSingleDemo"}
        videoLink={"https://schoolsmart360.com/videos/administration-smart.mp4"}
      />
      <br />
      <div className="container">
        <h2 className="text-center font-feature">Features overview</h2>

        <div className="row mt-5">
          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/inventory.31f2eab8.svg"
              // }
              icon={"icon-reporting-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"REPORTING"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"All custom reports generated by this module."} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReportSmart;
