import React, { useState } from 'react'
import "./videSection.scss";
import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import videos from "../../Assets/images/SSVideo.mp4"
import bgimg from "../../Assets/images/dasboardpic.png"


function VideoSection() {

    const [isOpen, setisOpen] = useState(false);

    return (
        <div className="videoSection">

            <div className="video-player" style={{ background: `url(${bgimg})` ,backgroundSize:"cover" , backgroundPosition: "center" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <ModalVideo channel='custom' isOpen={isOpen} url={videos} onClose={() => setisOpen(!isOpen)} />
                            <button onClick={() => setisOpen(!isOpen)} className="play-button"><i className="fas fa-play"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}
export default VideoSection;