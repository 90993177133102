import { Link } from "react-router-dom";
import sp from "../../Assets/support/Students_Parents.jpg";
import QuestionComp from "../../components/Questions/QuestionComp";
import Unaswered from "../../components/Questions/Unanswered";
const StudentParent = ()=>{

    return(
        <>
         <div
        className="heroSection"
        style={{ backgroundImage: "url(" + sp + ")" }}
      >
        <div className="container">
          <div className="row">
            <h1 className="text-center text-light head">
            {/* SchoolSmart Always Ready to 
Organise Different Events for School 
Community… */}
            </h1>
            <p className="text-center text-light pTag">
            {/* Seminars, webinars, Interactive Online meets with experts, workshops, 
eWorkshops. */}
            </p>
          </div>
        </div>
        <br />
      </div>
      <div className="textSection">
        <div className="container">
          <br />
          <h1 className="color">
          Page coming soon! 
          </h1>
          <p className="mt-3 bottom-text">
          {/* Many schools worldwide trust our system to support the future of education. We are one of the leading completely cloud-based information system providers. */}
          </p>
        </div>
      </div>
      {/* <QuestionComp 
      heading={"Interested by the possibilities??"}
      paragraph={"We’d love to show you more. Just get in touch with your questions or to request a no-obligation demo."}
      button={"Show me more SchoolSmart"}
      /> */}
      <Unaswered/>
        </>
    )
}

export default StudentParent