import { Link } from "react-router-dom";
import chatbot from "../../Assets/support/ChatBot.jpg";
import QuestionComp from "../../components/Questions/QuestionComp";
import Unaswered from "../../components/Questions/Unanswered";
const Chatbot = ()=>{

    return(
        <>
         <div
        className="heroSection"
        style={{ backgroundImage: "url(" + chatbot + ")" }}
      >
        <div className="container">
          <div className="row">
            <h1 className="text-center text-light head">
         
            </h1>
            <p className="text-center text-light pTag">
          
            </p>
          </div>
        </div>
        <br />
      </div>
      <div className="textSection">
        <div className="container">
          <br />
          <h1 className="color">
          Page coming soon! 
          </h1>
          <p className="mt-3 bottom-text">
         
          </p>
        </div>
      </div>
<Unaswered/>
        </>
    )
}

export default Chatbot