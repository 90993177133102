import React from "react";
import "./SocialMedia.scss";

const SocialMedia = () => {
  return (
    <>
   
      <div className="container">
        <div className="row sec">

            
          <div className="col-md-4 social-icon-backround">
            <br />
            <div className="row text-light " style={{justifyContent:"space-evenly"}}>
              <div className="col-md-4" >
              <i class="fab fa-facebook"></i> Facebook
              </div>
              <div className="col-md-4">
              <i class="fab fa-twitter"></i> Twitter
              </div>
              <div className="col-md-4">
              <i  class="fab fa-youtube"></i> YouTube
              </div>
            </div>
            <br />
            <div className="row text-light">
              
              <div className="col-md-4 mr-2" >
              <i class="fab fa-linkedin"></i> Linkedin
              </div>
              <div className="col-md-4">
              <i class="fab fa-google-plus-g"></i> Google+
              </div>
              <div className="col-md-4">
              <i  class="fab fa-instagram"></i> Instagram
              </div>
            </div>
            <br />
            <div className="row section-icon">
              <h3 style={{ color: "white" }}>SchoolSmart Community</h3>
              <br />
              <br />
              <p style={{ color: "white" }}>
                The #SchoolSmart #UserGroup We have lots to share including the
                #latest SchoolSmart #developments. It's free to attend, register
                here: https://
                <br />
                https://
                <br />
                25 December 2021
              </p>
            </div>
          </div>

          <div className="col-md-8 slider-background">
            <div className="row">
              <div
                id="carouselExampleIndicators"
                class="carousel slide"
                data-bs-ride="carousel"
              >
                <div class="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="0"
                    class="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  ></button>
                </div>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                   <div className="slider-textArea">
                   
                      <h3  style={{ color: "white" ,marginTop:"1rem"}}>Company News Update</h3>
                      
                      <h3  style={{ color: "white", marginTop:"2rem" }}>Android and iOS apps coming soon</h3>
                      {/* <br /> */}
                      <p  style={{ color: "white" }}>
                        The SchoolSmart apps will be launched soon. There will
                        be three different apps each for Parents, Students and
                        Staff. To improve communication these apps will be a
                        vital tool for the future.
                      </p>
                      
                      <p  style={{ color: "white" }}>28 October 2021</p>
                      </div>
                  </div>
                  <div class="carousel-item">
                    <div className="slider-textArea">
                  
                      <h3  style={{ color: "white",marginTop:"1rem" }}>Company News Update</h3>
                     
                      <h3  style={{ color: "white",marginTop:"2rem" }}>Android and iOS apps coming soon</h3>
                      {/* <br /> */}
                      <p  style={{ color: "white" }}>
                        The SchoolSmart apps will be launched soon. There will
                        be three different apps each for Parents, Students and
                        Staff. To improve communication these apps will be a
                        vital tool for the future.
                      </p>
                     
                      <p  style={{ color: "white" }}>28 October 2021</p>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div className="slider-textArea">
                        
                      <h3  style={{ color: "white",marginTop:"1rem" }}>Company News Update</h3>
                      
                      <h3  style={{ color: "white",marginTop:"2rem" }}>Android and iOS apps coming soon</h3>
                      {/* <br /> */}
                      <p  style={{ color: "white" }}>
                        The SchoolSmart apps will be launched soon. There will
                        be three different apps each for Parents, Students and
                        Staff. To improve communication these apps will be a
                        vital tool for the future.
                      </p>
                      
                      <p  style={{ color: "white" }}>28 October 2021</p>
                    </div>
                  </div>
                </div>
                {/* <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Next</span>
                </button> */}
              </div>
            </div>
          </div>
          </div>

      </div>
    </>
  );
};
export default SocialMedia;
