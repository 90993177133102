import React from "react";
import "./Affiliates.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import affilate1 from '../../Assets/affiliates/1.png';
import affilate2 from '../../Assets/affiliates/2.png';
import affilate3 from '../../Assets/affiliates/3.png';
import affilate4 from '../../Assets/affiliates/4.jpg';
import affilate5 from '../../Assets/affiliates/5.png';
import affilate6 from '../../Assets/affiliates/6.png';
import affilate7 from '../../Assets/affiliates/7.png';

const Affiliates = () => {
  return (
    <>
      <div className="affiliate">
        <div className="container">
          <div className="row">
            <div className="section">
              <div className="heading">
                <h2 >Proud Affiliate Partners of</h2>
              </div>
              <div className="container-fluid">
                <OwlCarousel
                  items={5}
                  className="owl-theme"
                  loop
                  nav
                  margin={9}
                >
                  <div>
                    <img
                      className="image"
                      src={
                        affilate1
                      }
                    />
                  </div>
                  <div>
                    <img
                      className="image"
                      src={
                        affilate2
                      }
                    />
                  </div>
                  <div>
                    <img
                      className="image"
                      src={
                        affilate3
                      }
                    />
                  </div>
                  <div>
                    <img
                      className="image"
                      src={
                        affilate4
                      }
                    />
                  </div>
                  <div>
                    <img
                      className="image"
                      src={
                        affilate5
                      }
                    />
                  </div>
                  {/* <div>
                    <img
                      className="image"
                      src={
                        affilate6
                      }
                    />
                  </div> */}
                  <div>
                    <img
                      className="image" 
                      src={
                        affilate7
                      }
                    />
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Affiliates;
