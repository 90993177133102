import caseStudy from "../../Assets/services/Consultancy.jpg";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import { Link } from "react-router-dom";
const Consultancy =()=>{
    return(
        <>
          <div
        className="heroSection"
        style={{ backgroundImage: "url(" + caseStudy + ")" }}
      >
        <div className="container">
          <div className="row">
            <h1 className="text-center text-light head">
            Anything you need, from a focus on 
admin, to academic management, to 
technical reporting services … 
            </h1>
            <p className="text-center text-light pTag">
            We’re always available to help.
            </p>
          </div>
        </div>
        <br />
      </div>
      <div className="textSection">
        <div className="container">
          <br />
          <h1 className="color">
          We provide complete Education Solutions…
          </h1>
          <p className="mt-3 bottom-text">
          Our goal is to assist and advise you at every step in achieving excellence in 
your academic and administration goals.
          </p>
        </div>
      </div>
      <br></br>
      <ServiceSection
        s1={"Services"}
        s2={<Link to ="/Installation" style={{textDecoration:'none',color:'black'}}>Installation</Link>}
        s3={"Services"}
        s4={<Link to ="/integration" style={{textDecoration:'none',color:'black'}}>Integration</Link>}
        s5={"Our Product"}
        s6={<Link to ="/product" style={{textDecoration:'none',color:'black'}}>Modules</Link>}
      />
        </>
    )
    }
    
    export default Consultancy;
    