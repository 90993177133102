import React from "react";
import "./Products.scss";
import academic from "../../Assets/headers/ACADEMIC.jpg";
import { Link } from "react-router-dom";
import ProductWithButton from "../../components/ProductSesction/ProductWithButton";
import academicScreen from "../../Assets/screens/academic-s.png";
import Feature from "../../components/Features/Feature";
const AcademicSmart = (props) => {
  return (
    <div>
      <div
        className="heroSection"
        style={{ backgroundImage: "url(" + academic + ")" }}
      >
        <div
          style={{
            backgroundColor: "#000",
            display: "inline-block",
            width: "100%",
            opacity: "0.7",
          }}
        >
          <div className="container">
            <div className="row">
              <h1 className="text-center text-light head" >
                A Complete Solution for all your academic requirements…
              </h1>
              <p className="text-center text-light pTag">
                A cloud-based tool, supporting your staff members to review the
                performance of students, subjects, attendance and so much more.
              </p>
              <p className="hp text-light">
                <Link className="text-light" to="/">
                  Home
                </Link>{" "}
                |{" "}
                <Link className="text-light" to="/product">
                  Products
                </Link>{" "}
              </p>
            </div>
          </div>
        </div>
        <br />
        <div className="textSection">
          <div className="container">
            <br />
            <h1  className="color">Smart Choice for Smart Educators</h1>
            <p className="mt-4 bottom-text">
              A set of modules to support the foundation of your school
              academics, right from managing exams, school reporting through to
              timetable management. Giving your staff the right tools to
              efficiently do their job.
            </p>
          </div>
        </div>
      </div>
      <br />
      <ProductWithButton
        backgroundColor={"#f0eeda"}
        src={academicScreen}
        heading={"ACADEMIC "}
        smallHeading={
          "A role-based efficient fully integrated academic management tool"
        }
        text={
          "Making sure you have everything that you need to complete your various daily tasks is essential. Safely storing data that syncs across your MIS system, AcademicSmart is designed so you can guide and keep track of your teachers and the subjects, students and classes they teach. This includes tools to help you create sets and synchronise timetables across the school."
        }
        link1={"/"}
        link2={"/SchedulSingleDemo"}
        videoLink={"https://schoolsmart360.com/videos/academic-smart.mp4"}
      />
      <br />
      <div className="container">
        <h2 className="text-center font-feature">Features overview</h2>

        <div className="row mt-5">
          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/learning%20management%20system.a448eab8.svg"
              // }
              icon={"icon-LMS-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}

            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"LMS"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"Learning Management System."} />
            </p>
          </div>
          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/competency%20management%20system.8bd968df.svg"
              // }
              icon={"icon-CMS-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
              
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"CMS"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"Competency Management System. "} />
            </p>
          </div>

          <div className="col-md-2">
            <Feature
              // img={"http://schoolsmart.org.uk/static/media/sid.80436d06.svg"}
              icon={"icon-SID-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"SID"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"Student Information Desk."} />
            </p>
          </div>
          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/academic%20head.231fc591.svg"
              // }
              icon={"icon-academic-head-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
              
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"ACADEMIC HEAD"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"Manager & HODS Roles."} />
            </p>
          </div>

          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/academicPlan.1b0c7253.svg"
              // }
              icon={"icon-academic-plan-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"ACADEMIC PLAN"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"Academic Plan Dashboard & Plan. "} />
            </p>
          </div>
          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/competency%20management%20system.8bd968df.svg"
              // }
              icon={"icon-CMS-admin-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"CMS ADMIN"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"Competency Management System & Admin."} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AcademicSmart;
