import React from "react";

function Checkbox(props) {
  return <div>
      <input
      type={props.type}
      onChange={props.onChange}
      value={props.value}
      />
      <label>{props.label}</label>
  </div>;
}
export default Checkbox;
