import React from "react";
import "./Products.scss";
import finance from "../../Assets/headers/FINANCE.jpg";
import { Link } from "react-router-dom";
import ProductWithButton from "../../components/ProductSesction/ProductWithButton";
import studentAdmission from "../../Assets/products/studentAdmisison.png";
import Feature from "../../components/Features/Feature";
const FinanceSmart = (props) => {
  return (
    <div>
      <div
        className="heroSection"
        style={{ backgroundImage: "url(" + finance + ")" }}
      >
        <div
          style={{
            backgroundColor: "#000",
            display: "inline-block",
            width: "100%",
            opacity: "0.7",
          }}
        >
          <div className="container">
            <div className="row">
              <h1  className="text-center text-light head">
                THE FinanceSmart!
              </h1>
              <p className="text-center text-light pTag">
                A simple yet advanced easy to use, fully secure and online
                financial system for your school.
              </p>
              <p className="hp text-light">
                <Link className="text-light" to="/">
                  Home
                </Link>{" "}
                |{" "}
                <Link className="text-light" to="/product">
                  Products
                </Link>{" "}
              </p>
            </div>
          </div>
        </div>
        <br />
        <div className="textSection">
          <div className="container">
            <br />
            <h1 className="color">
              A solution to support your long-term financial strategy and
              full-fill your short-term objective
            </h1>
            <p className="mt-4 bottom-text">
              Designed to bring your academic, wellbeing, administrative and fee
              billing elements together with a powerful accounting and finance
              solution.
            </p>
          </div>
        </div>
      </div>
      <br />
      <ProductWithButton
        backgroundColor={"#fff2cc"}
        src={studentAdmission}
        heading={"FINANCE "}
        smallHeading={
          "Accounting, consolidation and business intelligence features offer you the ability to generate predefined and custom reports, profiled by user."
        }
        text={
          "Every schools’ finance team requires comprehensive systems to support the practice of effectively managing money and budgets to support the schools’ long-term financial strategy. Our modules and FinanceSmartTM solution support these important financial tasks giving your finance team the best tools to align with the school’s strategic plan."
        }
        link1={"/"}
        link2={"/SchedulSingleDemo"}
        videoLink={"https://schoolsmart360.com/videos/finance-smart.mp4"}
      />
      <br />
      <div className="container">
        <h2 className="text-center font-feature">Features overview</h2>

        <div className="row mt-5">
          <div className="col-md-2">
            <Feature
              img={
                "http://schoolsmart.org.uk/static/media/account.419d1881.svg"
              }
             
              // icon={" icon-account-icon fa-6x"}
              // inn1={"path1"}
              // inn2={"path2"}
              // inn3={"path3"}
              // inn4={"path4"}
              // inn5={"path5"}
              // inn6={"path6"}
              // inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"ACCOUNTS"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"All accounts activities."} />
            </p>
          </div>
          <div className="col-md-2">
            <Feature
               img={"http://schoolsmart.org.uk/static/media/budget.24268bc6.svg"} 
              // icon={" icon-budget-icon fa-6x"}
              // inn1={"path1"}
              // inn2={"path2"}
              // inn3={"path3"}
              // inn4={"path4"}
              // inn5={"path5"}
              // inn6={"path6"}
              // inn7={"path7"}
/>
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"BUDGET"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"Plan budget for school."} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FinanceSmart;
