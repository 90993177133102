import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import "./SignupForm.scss";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import axios from "axios";
import SignupVideo from "../../Assets/images/SSVideo2.mp4"
// const sgMail = require("@sendgrid/mail");

const SignupForm = () => {
  const [openSuccessModal, setopenSuccessModal] = useState(false);
  const [contact, setcontact] = useState("");
  const [user, setUser] = useState({
    fName: "",
    lName: "",
    sName: "",
    country: "",
    email: "",
    pNumber: contact,
    jTitle: "",
    nStudent: "",
    cSystem: "",
    tSchool: "",
    aboutUs: "",
    sWebsite: "",
  });

  const {
    fName,
    lName,
    email,
    sName,
    country,
    pNumber,
    jTitle,
    nStudent,
    cSystem,
    tSchool,
    aboutUs,
    sWebsite,
  } = user;

  const onInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();

    var body = {
      email: user.email,
      firstName: user.fName,
      lastName: user.lName,
      school: user.tSchool,
      country: user.country,
      phone: user.pNumber,
      job: user.jTitle,
      nStudent: user.nStudent,
      mis: user.cSystem,
      ss: user.useSchoolSmart,
      about: user.aboutUs,
      tSchool: user.tSchool,
    };
    console.log("Data", body);
    await axios.post("https://embassyportal.schoolsmart.org.uk/api/SignupDemo", body)
      .then(function (response) {
        debugger;
        setopenSuccessModal(true);
      })
      .catch(function (error) {
        alert("Error");
        console.log(error)

      });
  };

  return (
    <div className="container">
      <Modal
        isOpen={openSuccessModal}
        centered
        fullscreen="xl"
        size="xl"
        contentClassName="successModal"
      // toggle={function noRefCheck() { }}
      >

        <ModalBody>
          {/* <div className="text-center">
            <i className="fas fa-check-circle fa-3x text-success"></i> <label>Success</label>
            <h3 className="mt-5">Your request has been submitted successfully</h3>
          </div> */}
          <div className="row justify-content-center">
            {/* <div className="col-md-2">
              <i className="fas fa-check-circle fa-2x text-success text-center"></i>
            </div> */}
            <div className="col-md-8 text-center">
              <h3 className=""> <i className="fas fa-check-circle text-success text-center mx-3"></i>Sign up Successful</h3>
            </div>
            <div className="col-md-12">
              <video width="100%" height="100%" controls>
                <source src={SignupVideo} type="video/mp4" />
                  <source src="movie.ogg" type="video/ogg" />
                    Your browser does not support the video tag.
                  </video>
            </div>
                <div className="col-md-10 mt-5">
                  <Link to="/">
                    <button className="btn btn-block btn-lg btn-success w-100">Continue</button>
                  </Link>
                </div>
          </div>
        </ModalBody>

      </Modal>
          <div className="row">
            <div className="section-signUp">
              <p className="mt-2 font-heading">
                Sign-Up to Watch our StudentSmart Demo
          </p>
              <p className="font mt-2">
                Find out why schools around the world trust SchoolSmart and let us
                introduce you to our admissions software to help streamline your
                student enrolment process, wherever you are.
          </p>
            </div>
          </div>

          <form onSubmit={(e) => onSubmit(e)}>
            <div className="row mt-3">
              <div className="form-group col-md-6">
                <label for="fName" className="label-label">
                  First Name
              <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fName"
                  name="fName"
                  value={fName}
                  onChange={(e) => onInputChange(e)}
                  required="true"
                />
                <label for="sName" className="label-label">
                  School Name
              <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="sName"
                  name="sName"
                  value={sName}
                  onChange={(e) => onInputChange(e)}
                  required="true"
                />
                <label for="email" className="label-label">
                  Email
              <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => onInputChange(e)}
                  required="true"
                />
                <label for="jTitle" className="label-label">
                  Job Tilte
              <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="jTitle"
                  name="jTitle"
                  value={jTitle}
                  onChange={(e) => onInputChange(e)}
                  required="true"
                />

                <label for="cSystem" className="label-label">
                  Current Admission system used at your school?{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="cSystem"
                  name="cSystem"
                  value={cSystem}
                  onChange={(e) => onInputChange(e)}
                  required="true"
                />
                <label for="lName" className="label-label">
                  How did you hear about us?
              <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="aboutUs"
                  name="aboutUs"
                  value={aboutUs}
                  onChange={(e) => onInputChange(e)}
                  required="true"
                />
              </div>

              <div className="col-md-6">
                <label for="lName" className="label-label">
                  Last Name
              <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lName"
                  name="lName"
                  value={lName}
                  onChange={(e) => onInputChange(e)}
                  required="true"
                />
                <label for="country" className="label-label">
                  Country
              <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="country"
                  name="country"
                  value={country}
                  onChange={(e) => onInputChange(e)}
                  required="true"
                />
                {/* <label for="country" className="label-label">
                  Phone Number
              <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="pNumber"
                  name="pNumber"
                  value={pNumber}
                  onChange={(e) => onInputChange(e)}
                  required="true"
                /> */}
                            <label for="country" className="label-label">
              Phone Number
              <span style={{ color: "red" }}>*</span>
            </label><br/>
                           <PhoneInput
                  className=""
                  international
                  defaultCountry="PK"
                  value={contact}
                  onChange={setcontact} />
                <label for="nStudent" className="label-label">
                  Number of Students
              <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="nStudent"
                  name="nStudent"
                  value={nStudent}
                  onChange={(e) => onInputChange(e)}
                  required="true"
                />
                <label for="tSchool" className="label-label">
                  Type of School
              <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="tSchool"
                  name="tSchool"
                  value={tSchool}
                  onChange={(e) => onInputChange(e)}
                  required="true"
                />
                <label for="sWebsite" className="label-label">School Website</label>
                <input
                  type="sWebsite"
                  className="form-control"
                  id="sWebsite"
                  name="sWebsite"
                  value={sWebsite}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>
            <div className="text-section mt-3 font">
              <p>
                SchoolSmart by LinkIT is committed to protecting and respecting your
                privacy, and we’ll only use your personal information to administer
                your account and to provide the products and services you requested
                from us. From time to time, we would like to contact you about our
                products and services, as well as other content that may be of
                interest to you. If you consent to us contacting you for this
                purpose, please tick below to say how you would like us to contact
                you:
          </p>
            </div>
            <div className="checkbox-class ">
              <input className="" type="checkbox" required="true" />{" "}
              <p style={{ display: "inline-block" }} className="label-label ">
                I agree to receive other communications from SchoolSmart.
          </p>
              <p className="text-section font" >
                In order to provide you the content requested, we need to store and
                process your personal data. If you consent to us storing your
                personal data for this purpose, please tick the checkbox below
          </p>
            </div>
            <input type="checkbox" required="true" />{" "}
            <p style={{ display: "inline-block" }} className="label-label">
              I agree to allow SchoolSmart to store and process my personal data.
          <span style={{ color: "red" }}>*</span>
            </p>
            <br />
            <button className="btn btn-primary btn-block mb-5 mt-5">Submit</button>
          </form>
    </div>
  );
};
export default SignupForm;
