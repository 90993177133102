import oneBrandSchool from "../../Assets/solution/One-Branched-Schools.jpg";
import RichSolution from "../../components/FeatureRichSolution/RichSolution";
import Question from "../../components/Questions/Question";
const OneBranchSchool = () => {
  return (
    <>
      <div
        className="heroSection"
        style={{ backgroundImage: "url(" + oneBrandSchool + ")" }}
      >
        <div className="container">
          <div className="row">
            <h1 className="text-center text-light head">
              A Complete Cloud-based Solution for One Branched School
            </h1>
            <p className="text-center text-light pTag">
              Powering your school with an integrated school management and
              student information desk
            </p>
          </div>
        </div>
        <br />
      </div>
      <div className="textSection">
        <div className="container">
          <br />
          <h1 className="color">
            Integrated software to grow with your school and enhance the
            experience for staff, students and parents
          </h1>
          <p className="mt-3 bottom-text">
            Many schools worldwide trust our system to support the future of
            education. We are one of the leading completely cloud-based
            information system providers.
          </p>
        </div>
      </div>
      <RichSolution />
      <br></br>
      <Question />
    </>
  );
};

export default OneBranchSchool;
