const Form = (props) => {
    return (
      <>
        <div className="container mb-2">
          <div className="mt-5 align">
            <h2 className="interested-text  text-center mb-2">
              APPLICATION DETAILS
            </h2>
          </div>
          <form>
            <div class="mb-3">
  <label for="exampleFormControlInput1" class="form-label">First Name</label>
  <input type="fName" class="form-control" id="exampleFormControlInput1" placeholder="John" required/>
</div>
<div class="mb-3">
  <label for="exampleFormControlInput1" class="form-label">Last Name</label>
  <input type="lName" class="form-control" id="exampleFormControlInput1" placeholder="Doe" required/>
</div>
<div class="mb-3">
  <label for="exampleFormControlInput1" class="form-label">Email address</label>
  <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com" required/>
</div>
<div class="mb-3">
  <label for="exampleFormControlInput1" class="form-label">Mobile Number</label>
  <input type="mobile" class="form-control" id="exampleFormControlInput1" required/>
</div>
<div class="mb-3">
  <label for="exampleFormControlInput1" class="form-label">Country</label>
  <input type="country" class="form-control" id="exampleFormControlInput1" placeholder="Country" required/>
</div>
<div class="mb-3">
  <label for="exampleFormControlInput1" class="form-label">City</label>
  <input type="country" class="form-control" id="exampleFormControlInput1" placeholder="City" required/>
</div>
<div class="mb-3">
  <label for="exampleDataList" class="form-label">Position For Applied</label>
<input class="form-control" list="datalistOptions" id="exampleDataList" placeholder="Type to search..."></input>
<datalist id="datalistOptions">
      <option> Web Develoment</option>
      <option>Mobile Application Develoment</option>
      <option>Graphic Designing</option>
      <option>Digital Marketing</option>
      <option>Content Writing</option>
      <option>Physic Teacher</option>
      <option>Chemistry Teacher</option>
      <option>Biology Teacher</option>
      <option>Mathematics Teacher</option>
      <option>General Science Teacher</option>
      <option>English Teacher</option>
      </datalist>
</div>

<div class="mb-3">
  <label for="exampleFormControlTextarea1" class="form-label">Describe your skill</label>
  <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
</div>
<div class="mb-3">
  <label for="formFile" class="form-label">Upload Resume</label>
  <input class="form-control" type="file" id="formFile"/>
</div>
<div class="mb-3">
  <label for="exampleFormControlTextarea1" class="form-label">Training and Certification</label>
  <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
</div>
<button className="btn btn-primary mt-2 mb-4">Submit</button>

            </form>
        </div>
      </>
    );
  };
  export default Form;
  