import Migrations from "../../Assets/services/Migration.jpg";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import { Link } from "react-router-dom";
const Migration = () => {
  return (
    <>
              <div
        className="heroSection"
        style={{ backgroundImage: "url(" + Migrations + ")" }}
      >
        <div className="container">
          <div className="row">
            <h1 className="text-center text-light head">
            Why Migrate to SchoolSmart…
            </h1>
            <p className="text-center text-light pTag">
            We created SchoolSmart because there was nothing on the market capable of 
doing what educators really wanted an MIS to do.

            </p>
          </div>
        </div>
        <br />
      </div>
      <div className="textSection">
        <div className="container">
          <br />
          <h1 className="color">
          Switch your school to a more versatile MIS…
          </h1>
          <p className="mt-3 bottom-text">
          SchoolSmart is a cloud-based system where schools benefit from a cloud hosted solution because staff, students and parents can use the system at 
home and on the move, as well as school. It’s not just more efficient, it lifts 
communication and engagement across the school.
          </p>
        </div>
      </div>
      <br></br>
      <ServiceSection
       s1={"Services"}
       s2={<Link to ="/installation" style={{textDecoration:'none',color:'black'}}>Installation</Link>}
       s3={"Services"}
       s4={<Link to ="/training" style={{textDecoration:'none',color:'black'}}>Training</Link>}
       s5={"Our Product"}
       s6={<Link to ="/product" style={{textDecoration:'none',color:'black'}}>Modules</Link>}
      />
    </>
  );
};

export default Migration;
