import React, { useState } from "react";
import 'react-whatsapp-widget/dist/index.css'
import WhatsAppWidget from 'react-whatsapp-widget'
const Whatsapp = () => {
 return(
     <>
 <WhatsAppWidget phoneNumber='+96893923475'/>
     </>
  );
};
export default Whatsapp;
