import { Link } from "react-router-dom";
import press from "../../Assets/whySchoolSmart/Press.jpg";
import QuestionComp from "../../components/Questions/QuestionComp";
const Press = ()=>{

    return(
        <>
         <div
        className="heroSection"
        style={{ backgroundImage: "url(" + press + ")" }}
      >
        <div className="container">
          <div className="row">
            <h1 className="text-center text-light head">
            Press…
            </h1>
            <p className="text-center text-light pTag">
            All that you want to hear from the world of SchoolSmart.
            </p>
          </div>
        </div>
        <br />
      </div>
      <div className="textSection">
        <div className="container">
          <br />
          <h1 className="color">
          We are updating this page, we will be coming back soon! 
          </h1>
          <p className="mt-3 bottom-text">
         Coming Soon!
          </p>
        </div>
      </div>
      <QuestionComp 
      heading={"Media enquiries or News?"}
      paragraph={"If you would like SchoolSmart input or you would like to share for an education-related news story, please contact us on +(968) 79933088 or email marketing@schoolsmart360.com."}
      button={"Give us a call"}
      />
        </>
    )
}

export default Press