import React from "react";
import "./ProductSection.scss";
import { Link } from "react-router-dom";

const ProductSectionLeft = (props) => {
  return (
    <div>
      <div className="mb-4 container">
        <div className="row">
          <div
            className="col-md-9 leftSpacing"
            style={{
              backgroundColor: props.backgroundColor,
              borderRadius: "40px",
            }}
          >
            <div >
            <h3 className="font"><b>{props.heading}<p className="specialS">S</p>MART </b></h3>
              <h5 className="heading5 mb-2" >{props.smallHeading}</h5>
              <p className="lowerText">{props.text}</p>
              <span></span>{" "}
              <Link
                style={{ display: "inline-block" }}
                className="font"
                to={props.link}
              >
                <button to={props.link1} className="productView" onClick={() => localStorage.setItem("product-name", props.heading )}>
                  View
                </button>
              </Link>
            </div>
          </div>
          <div
            className="col-md-3  "
            style={{
              backgroundColor: props.backgroundColor,
              borderRadius: "40px",
              textAlign: "center",
              height: "300px",
            }}
          >
            <img className="img" src={props.src} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductSectionLeft;
