import "./ServiceSection.scss";
const ServiceSection = (props) => {
  return (
    <>
      <div className="container">
        <div className="" style={{ backgroundColor: "#f1f1f1" }}>
          <h2 className="interested-text  text-center mb-4">
            You may also be interested in…{" "}
          </h2>
        </div>
        <div
          className="mb-4 container"
          id="row-rev"
          style={{ backgroundColor: "#bfbfbf", display: "flex" }}
        >
          <div className="boxColor">
          
            <p className="mt-2 pLeft mb-4" style={{color:'red',fontSize:'1.2rem'}}>{props.s1}</p>
            <h3 className="mt-2 pLeft">{props.s2}</h3>

          </div>
          <div className="boxColor">
          
          <p className="mt-2 pLeft mb-4" style={{color:'#006400',fontSize:'1.2rem'}}>{props.s3}</p>
          <h3 className="mt-2 pLeft">{props.s4}</h3>
          </div>
          <div className="boxColor">
          <p className="mt-2 pLeft mb-4" style={{color:'#2aa3dd',fontSize:'1.2rem'}}>{props.s5}</p>
          <h3 className="mt-2 pLeft">{props.s6}</h3>
          </div>
        </div>

        <div className="mt-5 align">
          <h2 className="interested-text  text-center mb-4">
          Have unanswered questions?
          </h2>
          <p className="mt-2 mb-2 text-center" style={{fontSize:'1.25rem'}}>Sometimes it’s easier to chat – we’re always happy to talk in detail</p>
         <button className="btn btn-primary mt-2 mb-4" >Give us a call</button>
        </div>
      </div>
    </>
  );
};

export default ServiceSection;
